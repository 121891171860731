import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/john-r-bentson2.jpg";
  const fullName = `John R. Bentson, MD`;
  const zoomUrl =
    "https://us02web.zoom.us/j/88634774196?pwd=K3lIZW1BR2VLOUJsZGdMNUxZQTVKZz09";
  const zoomPhoneNumber = "(778) 907-2071";
  const zoomMeetingId = "886 3477 4196";
  const zoomMeetingPasscode = "951612";
  const birthDate = "";
  const deathDate = "Passed away December 28, 2020";
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 30);
  const memorialTime = "1pm PST";
  const inviteText = `John R. Bentson, MD, passed away December 28, 2020. Known as JB to his medical colleagues, he approached everything he did with great energy and focus. At this difficult time, in lieu of a physical ceremony, the family invites you raise a virtual toast to him at a virtual memorial honoring his life and work.
    \nPlease join us in remembering and celebrating him. In his work and play, he touched so many who appreciated his many talents and love for life. The world is less without him and will miss him. But we can continue his generosity of spirit, giving of ourselves to others around us as our talents allow.
    \nJohn, distinguished neuroradiologist, teacher, researcher, sportsman, family man, and role-model of dedication and excellence in all things, has departed this world upon completing a life of adventure and excitement. Please join us in celebrating the memories of one who lived a full life and shared it with so many.
    \nBoth an annual award and a research fund have been established in his honor. The John Bentson Award for Clinical Excellence will be given to an outgoing Radiology Resident or Neuroradiology Fellow with outstanding clinical skills, starting with the graduating class of 2021. In addition, John R. Bentson Research Fund will fund neuroscience research in the UCLA Radiology Department.`;
  const inviteOverride = (
    <p>
      In lieu of flowers, contributions may be made to the John R. Bentson
      Research Fund online by visiting{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://giving.ucla.edu/JohnBentson"
      >
        giving.ucla.edu/JohnBentson
      </a>
      . Alternatively, you may make a gift by check, payable to “The UCLA
      Foundation”, indicating the gift is in memory of John R. Bentson in the
      memo line, and mailing the check to The UCLA Foundation, Attn: Allyson
      Hamilton, PO Box 7145, Pasadena, CA 91109-9903. Contact Allyson Hamilton
      at 310-206-2621 or{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="mailto:AllysonHamilton@mednet.ucla.edu"
      >
        AllysonHamilton@mednet.ucla.edu
      </a>{" "}
      with any questions.
    </p>
  );
  const obituary = `John R. Bentson, age 83, of Pacific Palisades, California, died on Monday December 28, 2020 at the UCLA Ronald Reagan hospital.  John was born in 1937 in La Crosse Wisconsin to Carl and Stella (Hagen) Bentson. He married Sheridan Murphy in 1969.  
  \nJohn grew up on a working dairy farm in Viroqua, Wisconsin and graduated from Viroqua High School. He attended the University of Wisconsin, Madison, receiving a B.S. (Phi Beta Kappa) in 1957 and an M.D. in 1961. He interned for one year in Medicine at the University of Minnesota Hospital in Minneapolis and then returned to the University of Wisconsin Hospital for his Residency in Radiology, completed in 1965. While in his residency, he received a Smith, Kline & French Fellowship, which sent him to Peru to fly to remote tribal areas as a doctor alongside the missionaries. John served two years as a Captain in the US Army in Korea and Seattle.  In 1967, he received an NIH Special Fellowship in Neuroradiology at the UCLA School of Medicine, Los Angeles.  The following year, he returned to the University of Wisconsin Hospital for one year as Chief of Neuroradiology.  The remainder of his professional career he spent at UCLA as a Neuroradiologist.  He served as Chief of the Neuroradiology Section in the Radiology Department from 1971-2002, and served as Chief of the Diagnostic Radiology Division from 1984-1986. In 1986, he became Chief of Neuro/Angio Division.
  \nJohn is most remembered for developing the Bentson Guidewires (a series of internationally popular angiographic guidewires manufactured and marketed by Cook, Inc., Bloomington, IN, since 1973).  Known as JB by his colleagues, he was active as a clinician, teacher and researcher.  He received the Leo G. Rigler Teaching Award in 1995 and the Roche Award in 1960. He made over 150 presentations, wrote over 100 published papers, and wrote chapters and contributions to over 20 books. In addition, he contributed to his profession in numerous ways.  He was a peer reviewer of professional papers on five editorial boards. He served on numerous University and Hospital Committees, including chairing the Medical Risk Management Committee for 10 years. 
  \nOutside of the hospital setting, JB furthered education by serving as an examiner for American Board of Radiology Examination. Additionally, he was part of the creation of the Certificate of Added Qualifications (CAQ) in Neuroradiology in 1995, continuing to serve as an examiner for the CAQs. In 1980, he was a Founding Member of the International Working Group of Interventional Neuroradiology.
  \nPrivately, JB was always involved with sports activities, starting with ski jumping as a teenager.  Also as a teenager, he started sailing by rigging a sail on a kayak and sailing it down the Mississippi River.  Later he continued with down-hill skiing, including helicopter skiing, as well as racing his well-loved Ranger 26 sailboat, hand-ball, tennis, running, scuba diving, windsurfing, and even amateur bicycle racing.  He played the piano and loved music, especially classical music.  He also loved languages and cultures and was always ready to learn a new language and visit another country.
  \nJohn, distinguished neuroradiologist, teacher, researcher, sportsman, family man, and role-model of dedication and excellence in all things, has completed a life of adventure and excitement.  John is survived by his wife Sheridan Bentson; three children Erika Drazan, Krista Bentson, and Derek Bentson; six grandchildren; and his sister Ruth White. He was preceded in death by his brother Charles Bentson.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      title: "Memorial Recording",
      url: "https://storage.googleapis.com/afterword-static/recordings/John%20R.%20Bentson%2C%20MD%20-%20Recording.mp4",
    },
  ];

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      splashBackgroundOverride={`background-color: #E2F8FB; ]`} // background-image: url(/images/people/john-r-bentson-bg.jpg)
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      inviteOverride={inviteOverride}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    ></StoryPage>
  );
};

export default ThisPage;
